<template>
  <slot name="trigger" :open="handleOpen">
    <nd-button
      class="whitespace-nowrap"
      :class="{ active: triggerActive }"
      size="small"
      type="secondary"
      :icon="props.triggerIcon"
      @click="handleOpen"
    >
      {{ props.triggerLabel }}
    </nd-button>
  </slot>
  <n-drawer
    v-model:show="visible"
    class="bottom-drawer"
    placement="bottom"
    v-bind="$attrs"
    :z-index="zIndex.drawer"
    :height="drawerHeight || 'auto'"
    :style="customCssVars"
    @after-leave="handleClose"
  >
    <n-drawer-content>
      <template #header>
        <slot name="header">
          <div class="flex justify-between items-center px-3 py-2">
            <div ref="actionRef" class="action flex items-center min-w-10 max-w-105px">
              <nd-button v-if="props.showAction" class="active" type="text" size="small" :truncate-text="true" @click="handleActionClick">
                {{ props.actionLabel }}
              </nd-button>
            </div>
            <div class="flex flex-1 justify-center items-center">
              <span class="line-clamp-1">{{ props.title }}</span>
            </div>
            <div class="flex justify-end items-center" :style="cancelStyle">
              <svg-icon name="cancel" class="cursor-pointer w-10 h-10 p-2" @click="handleCrossButtonClick" />
            </div>
          </div>
        </slot>
      </template>
      <template #default>
        <div class="content">
          <slot :close="handleCrossButtonClick"></slot>
        </div>
      </template>
      <template #footer>
        <slot name="footer">
          <div v-if="props.showFooter" class="drawer-footer w-full py-3 px-5">
            <nd-button type="primary" class="w-full" :loading="props.isLoading" :disabled="!props.isSubmittable" @click="handleSubmit">
              {{ props.submitLabel }}
            </nd-button>
          </div>
        </slot>
      </template>
    </n-drawer-content>
  </n-drawer>
</template>

<script setup lang="ts">
import { NDrawer, NDrawerContent } from 'naive-ui'
import { zIndex } from '@/theme.config'

const visible = defineModel('visible', { default: false })

const props = withDefaults(
  defineProps<{
    title?: string
    triggerLabel?: string
    triggerIcon?: string
    triggerActive?: boolean
    showAction?: boolean
    showFooter?: boolean
    actionLabel?: string
    submitLabel?: string
    isSubmittable?: boolean
    isLoading?: boolean
    drawerHeight?: string | number
  }>(),
  {
    title: '',
    triggerLabel: '',
    triggerIcon: '',
    triggerActive: false,
    actionLabel: () => useI18n().t('all_clear'),
    showAction: false,
    showFooter: false,
    // @FIXME: waiting for PM to update i18n json
    submitLabel: '顯示',
    isSubmittable: true,
    isLoading: false,
    drawerHeight: 'auto',
  }
)

const emit = defineEmits<{
  (event: 'action'): void
  (event: 'submit'): void
  (event: 'close'): void
}>()

const actionRef = ref<Nullable<HTMLDivElement>>(null)

const triggerActive = computed(() => visible.value || props.triggerActive)

const customCssVars = computed(() => {
  let maxHeight = 128
  if (props.showFooter) maxHeight += 64

  return {
    '--drawer-body-max-height': `calc(100dvh - ${maxHeight}px)`,
  }
})

const cancelStyle = computed(() => ({ ...(actionRef.value && { width: `${actionRef.value.offsetWidth}px` }) }))

const handleActionClick = () => emit('action')

const handleSubmit = () => {
  visible.value = false
  emit('submit')
}

const handleOpen = () => {
  visible.value = true
}

const handleCrossButtonClick = () => {
  visible.value = false
  handleClose()
}

const handleClose = () => {
  emit('close')
}
</script>

<style lang="scss">
.bottom-drawer {
  @apply overflow-hidden rounded-t-12px;
  &.n-drawer.n-drawer--bottom-placement {
    .n-drawer-header {
      @apply sticky top-0 bg-white z-10;
      .n-drawer-header__main {
        @apply w-full;
      }
    }
    .n-drawer-body {
      @apply flex-1;
    }
    .n-drawer-body-content-wrapper {
      @apply overflow-y-auto;
      max-height: var(--drawer-body-max-height);
    }
    .n-drawer-footer {
      @apply bg-white;
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    }
  }
}
</style>
